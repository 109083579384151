let AUTH_URL,
  CORE_URL,
  API_URL,
  NOTIFICATION_URL,
  FILE_URL_API,
  FILE_URL,
  DEFAULT_AVATAR,
  DEFAULT_IMG,
  WEBSITE_URL,
  WEBSITE_CLIENT_URL,
  FIREAPI_URL,
  SCOPES,
  DOMAIN;

const hName = window.location.hostname;

if (hName.includes("localhost") || hName.includes("dev.neomedic.vn")) {
  AUTH_URL = "https://testauth.neomedic.vn";
  CORE_URL = "https://testcore.neomedic.vn/api/v1/";
  API_URL = "https://testclinicapi.neomedic.vn/api/v1/";
  NOTIFICATION_URL = "https://testnotification.neomedic.vn";
  FILE_URL_API = "https://testfile.neomedic.vn/api/v1/";
  FILE_URL = "https://testfile.neomedic.vn/";
  DEFAULT_AVATAR = "https://testfile.neomedic.vn/uploads/images/no-avatar.png";
  DEFAULT_IMG = "https://testfile.neomedic.vn/uploads/images/no-image.png";
  WEBSITE_URL = "https://testwebsiteapi.neomedic.vn/api/v1/";
  WEBSITE_CLIENT_URL = "https://testwebsiteclient.neomedic.vn/api/v1/";
  FIREAPI_URL = "https://testfirebase.neomedic.vn/api/v1/";
  SCOPES =
    "GHM_Core GHM_Gateway GHM_Notification GHM_FileManagement GHM_FireBase GHM_Clinic GHM_Website offline_access openid profile email";
  DOMAIN = "quanly.clinic.vn";
}else if (hName.includes("dev.clinic-cms")) {
  AUTH_URL = "https://auth.thaithinhmedic.com";
  CORE_URL = "https://core.thaithinhmedic.com/api/v1/";
  API_URL = "https://clinicapi.thaithinhmedic.com/api/v1/";
  NOTIFICATION_URL = "https://notification.thaithinhmedic.com";
  FILE_URL_API = "https://file.thaithinhmedic.com/api/v1/";
  FILE_URL = "https://file.thaithinhmedic.com";
  DEFAULT_AVATAR = "https://spafile.ghmsoft.vn/uploads/images/no-avatar.png";
  DEFAULT_IMG = "https://spafile.ghmsoft.vn/uploads/images/no-image.png";
  WEBSITE_URL = "https://websiteapi.thaithinhmedic.com/api/v1/";
  FIREAPI_URL = "https://firebase.thaithinhmedic.com/api/v1/";
  SCOPES =
    "GHM_Core GHM_Gateway GHM_Notification GHM_FileManagement GHM_FireBase GHM_Clinic GHM_Website offline_access openid profile email";
  DOMAIN = "quanly.clinic.vn";
} else if (hName.includes("clinic-cms")) {
  AUTH_URL = "https://cmsapi.thaithinhmedic.vn/auth";
  CORE_URL = "https://cmsapi.thaithinhmedic.vn/core/api/v1/";
  API_URL = "https://cmsapi.thaithinhmedic.vn/clinic/api/v1/";
  NOTIFICATION_URL = "https://cmsapi.thaithinhmedic.vn/notification/api/v1";
  FILE_URL_API = "https://cmsapi.thaithinhmedic.vn/file/api/v1/";
  FILE_URL = "https://cmsapi.thaithinhmedic.vn/file";
  DEFAULT_AVATAR = "https://spafile.ghmsoft.vn/uploads/images/no-avatar.png";
  DEFAULT_IMG = "https://spafile.ghmsoft.vn/uploads/images/no-image.png";
  WEBSITE_URL = "https://cmsapi.thaithinhmedic.vn/websiteapi/api/v1/";
  FIREAPI_URL = "https://cmsapi.thaithinhmedic.vn/firebase/api/v1/";
  SCOPES =
    "GHM_Core GHM_Gateway GHM_Notification GHM_FileManagement GHM_FireBase GHM_Clinic GHM_Website offline_access openid profile email";
  DOMAIN = "quanly.clinic.vn";
} else if (hName.includes("jspa-cms")) {
  AUTH_URL = "https://ghmspaauth.ghmsoft.vn";
  CORE_URL = "https://ghmspacore.ghmsoft.vn/api/v1/";
  API_URL = "https://ghmspaapi.ghmsoft.vn/api/v1/";
  NOTIFICATION_URL = "https://ghmspanotification.ghmsoft.vn";
  FILE_URL_API = "https://ghmspafile.ghmsoft.vn/api/v1/";
  FILE_URL = "https://ghmspafile.ghmsoft.vn/";
  DEFAULT_AVATAR = "https://ghmspafile.ghmsoft.vn/uploads/images/no-avatar.png";
  DEFAULT_IMG = "https://ghmspafile.ghmsoft.vn/uploads/images/no-image.png";
  WEBSITE_URL = "https://ghmspawebsite.ghmsoft.vn/api/v1/";
  FIREAPI_URL = "https://ghmspafirebase.ghmsoft.vn/api/v1/";
  SCOPES =
    "GHM_Core GHM_Gateway GHM_Notification GHM_FileManagement GHM_FireBase GHM_NailSpa GHM_Website offline_access openid profile email";
  DOMAIN = "quanlyspa.jadespa.vn";
} else if (hName.includes("neo-cms")) {
  AUTH_URL = "https://auth.neomedic.vn";
  CORE_URL = "https://core.neomedic.vn/api/v1/";
  API_URL = "https://clinicapi.neomedic.vn/api/v1/";
  NOTIFICATION_URL = "https://notification.neomedic.vn";
  FILE_URL_API = "https://file.neomedic.vn/api/v1/";
  FILE_URL = "https://file.neomedic.vn/";
  DEFAULT_AVATAR = "https://file.neomedic.vn/uploads/images/no-avatar.png";
  DEFAULT_IMG = "https://file.neomedic.vn/uploads/images/no-image.png";
  WEBSITE_URL = "https://websiteapi.neomedic.vn/api/v1/";
  WEBSITE_CLIENT_URL = "https://websiteclient.neomedic.vn/api/v1/";
  FIREAPI_URL = "https://firebase.neomedic.vn/api/v1/";
  SCOPES =
    "GHM_Core GHM_Gateway GHM_Notification GHM_FileManagement GHM_FireBase GHM_Clinic GHM_Website offline_access openid profile email";
  DOMAIN = "quanly.clinic.vn";
}

export {
  AUTH_URL,
  CORE_URL,
  API_URL,
  NOTIFICATION_URL,
  FILE_URL_API,
  FILE_URL,
  DEFAULT_AVATAR,
  DEFAULT_IMG,
  WEBSITE_URL,
  WEBSITE_CLIENT_URL,
  FIREAPI_URL,
  SCOPES,
  DOMAIN,
};

export const ALPHABET = {
  "en-US": [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ],
  "vi-VN": [
    "A",
    "Ă",
    "Â",
    "B",
    "C",
    "D",
    "Đ",
    "E",
    "Ê",
    "G",
    "H",
    "I",
    "K",
    "L",
    "M",
    "N",
    "O",
    "Ô",
    "Ơ",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "Ư",
    "V",
    "X",
    "Y",
  ],
};

export const ADVERTISEMENT_TYPE = [
  { id: 0, "name-vi-VN": "Video Bắt đầu", "name-en-US": "Start Videos" },
  { id: 1, "name-vi-VN": "Video Kết thúc", "name-en-US": "End Videos" },
  { id: 2, "name-vi-VN": "Nhạc nền", "name-en-US": "Background Musics" },
];

export const ADVERTISEMENT_FILE_TYPE = [
  { id: 0, "name-vi-VN": "Ảnh", "name-en-US": "Photo" },
  { id: 1, "name-vi-VN": "Video", "name-en-US": "Video" },
  { id: 2, "name-vi-VN": "Nhạc", "name-en-US": "Music" },
];

export const CLIENT_ID = "GHMSOFTCLIENT";
export const DOMAIN_TYPE = "0";
export const CLIENT_SECRET = "GHMSOFT";

export const DEFAULT_NO_IMG = "/uploads/images/no-image.png";

export const THEMES_LIST = [
  { value: "dx.dark.css", name: "Dark" },
  { value: "dx.light.css", name: "Light" },
  { value: "dx.darkviolet.css", name: "DarkViolet" },
  { value: "dx.carmine.css", name: "Carmine" },
  { value: "dx.darkmoon.css", name: "Darkmoon" },
  { value: "dx.greenmist.css", name: "GreenMist" },
  { value: "dx.contrast.css", name: "Contrast" },
  { value: "dx.softblue.css", name: "SoftBlue" },
  { value: "dx.material.teal.light.css", name: "Material Teal Light" },
  { value: "dx.material.teal.dark.css", name: "Material Teal Dark" },
  { value: "dx.material.blue.light.css", name: "Material Blue Light" },
  { value: "dx.material.blue.dark.css", name: "Material Blue Dark" },
];

export const NEWS_STATUS = [
  { id: 0, name: '{"vi-VN":"Nháp","en-US":"Draft"}' },
  { id: 1, name: '{"vi-VN":"Đang chờ","en-US":"Pending"}' },
  { id: 2, name: '{"vi-VN":"Đã duyệt","en-US":"Approved"}' },
  { id: 3, name: '{"vi-VN":"Từ chối","en-US":"Declined"}' },
];

export const NEWS_TYPES = [
  { id: 0, name: '{"vi-VN":"Tin tức", "en-US":"News" }' },
  { id: 1, name: '{"vi-VN":"Sự kiện", "en-US":"Event"}' },
];

export const MENU_OBJECT_TYPES = [
  { id: 0, name: '{"vi-VN":"Tùy chỉnh", "en-US":"Custom"}' },
  { id: 1, name: '{"vi-VN":"Chuyên mục tin tức", "en-US":"Category News"}' },
  { id: 2, name: '{"vi-VN":"Tin tức", "en-US":"News"}' },
  {
    id: 3,
    name: '{"vi-VN":"Chuyên mục sản phẩm", "en-US":"Category Product"}',
  },
  { id: 4, name: '{"vi-VN":"Sản phẩm", "en-US":"Product"}' },
  { id: 5, name: '{"vi-VN":"Dịch vụ", "en-US":"Service"}' },
  {
    id: 6,
    name: '{"vi-VN":"Phản hồi khách hàng", "en-US":"Customer feedback"}',
  },
  { id: 7, name: '{"vi-VN":"Nhóm FAQ", "en-US":"FAQ group"}' },
  { id: 8, name: '{"vi-VN":"Album", "en-US":"Album"}' },
];

export const MENU_POSITION = [
  { id: 0, name: '{"vi-VN":"Trên","en-US":"Top"}' },
  { id: 1, name: '{"vi-VN":"Dưới","en-US":"Bottom"}' },
  { id: 2, name: '{"vi-VN":"Trái","en-US":"Left"}' },
  { id: 3, name: '{"vi-VN":"Phải","en-US":"Right"}' },
  { id: 4, name: '{"vi-VN":"Giữa","en-US":"Center"}' },
];

export const BANNER_POSITION = [
  { id: 0, name: '{"vi-VN":"Trên","en-US":"Top"}' },
  { id: 1, name: '{"vi-VN":"Dưới","en-US":"Bottom"}' },
  { id: 2, name: '{"vi-VN":"Trái","en-US":"Left"}' },
  { id: 3, name: '{"vi-VN":"Phải","en-US":"Right"}' },
  { id: 4, name: '{"vi-VN":"Giữa","en-US":"Center"}' },
  { id: 5, name: '{"vi-VN":"Popup","en-US":"Popup"}' },
];

export const BANNER_TYPE = [
  { id: 0, name: '{"vi-VN":"Ảnh","en-US":"Image"}' },
  { id: 1, name: '{"vi-VN":"Video", "en-US":"Video"}' },
];

export const CONTACT_TYPE = [
  { id: 0, name: '{"vi-VN":"Email","en-US":"Email"}' },
  { id: 1, name: '{"vi-VN":"Số điện thoại","en-US":"Phone Number"}' },
  { id: 2, name: '{"vi-VN":"Mã số thuế","en-US":"TIN"}' },
  { id: 3, name: '{"vi-VN":"KvK","en-US":"KvK"}' },
];

export const BOOKING_STATUS = [
  { id: 1, name: '{"vi-VN":"Đang chờ","en-US":"Pending"}' },
  { id: 2, name: '{"vi-VN":"Đã duyệt","en-US":"Approved"}' },
  { id: 3, name: '{"vi-VN":"Từ chối","en-US":"Rejected"}' },
];

export const ALBUM_TYPE = [
  { id: 0, name: '{"vi-VN":"Ảnh","en-US":"Photo"}' },
  { id: 1, name: '{"vi-VN":"Video","en-US":"Video"}' },
];

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = [
  "Arial",
  "Courier New",
  "Georgia",
  "Impact",
  "Lucida Console",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];
const headerValues = [ false, 1, 2, 3, 4, 5 ];
export const HTML_OPTIONS = {
  toolbar: {
    multiline: true,
    items: [
      { name: "header", acceptedValues: headerValues },
      { name: "size", acceptedValues: sizeValues },
      { name: "font", acceptedValues: fontValues },
      "separator",
      "bold",
      "italic",
      "strike",
      "underline",
      "separator",
      "alignLeft",
      "alignCenter",
      "alignRight",
      "alignJustify",
      "separator",
      "orderedList",
      "bulletList",
      "image",
      "link",
      "separator",
      "insertTable",
      "deleteTable",
      "insertRowAbove",
      "insertRowBelow",
      "deleteRow",
      "insertColumnLeft",
      "insertColumnRight",
      "deleteColumn",
    ],
  },
  height: 700,
};
